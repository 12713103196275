.return-home a{
    text-decoration: none;
    color:white;
    padding:0.5rem 1rem;
    display: inline-block;
}

.return-home{
    position: fixed;
    left: 10px;
    top:10px;
}

.return-home button{
    padding: 0;
}

@media only screen and (max-width:1024px) and (min-width:200px){
.return-home{
    left:-6px;
    top:0;
}
.return-home button{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
}