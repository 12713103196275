.share-btn{
    position: fixed;
    border: 2px solid currentColor;
    background-color: white;
    box-shadow: 0px 0px 5px 3px gray;
    bottom: 5%;
    right: 6%;
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: bounce 1s infinite;
    &:hover{
        box-shadow: 0px 0px 10px 3px gray;
        animation-play-state: paused;
    }

}

@keyframes bounce {
    0%{
        bottom:5%;
        transform: scale(0.9);
    }
    30%{
        transform: scale(1);
        bottom:6%;
    }
    60%{
        transform: scale(0.9);
        bottom:5%
    }
    90%{
        bottom:7%;
        transform: scale(1.1);
    }
}