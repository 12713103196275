footer{
    /* background-color: var(--primary-font-color); */
    padding:0.5rem;
    display: flex;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    align-items: center;
    text-align: center;
}

.footer-links{
    flex-grow: 1;
}

.footer-links a {
    margin:0.5rem;
    display: block;
    font-weight: bold;
    text-decoration: none;
}

.footer-links a:hover{
    text-decoration: underline;
}

.footer-links a:visited{
    color:#0000EE;
}