.train-coach-details{
    text-align: center;
    width:80%;
    margin: auto;
}

.train-coach-details h2{
    font-weight: 500;
    margin: 1.5rem;
    text-decoration: underline;
}

.choice-btn{
    background: none;
    color: currentColor;
    display: inline-block;
    border:1px solid var(--primary-font-color);
    font-size: 20px;
    padding: 1rem;
    border-radius: 10px;
    margin: 0.5rem 1rem;
    cursor: pointer;
    font-weight: bold;
}

.choice-btn-active{
    background-color: var(--primary-font-color);
    color:white;
}

.choice-btn:hover{
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: var(--primary-font-color);
    color:white;
    transform: scale(1.01);
}

.train-coach-details ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.train-coach-details li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 1rem auto;
    width:29%;
    border:2px solid var(--primary-font-color);
    border-radius: 10px;
}

.berth-number{
    margin:0;
    background: var(--primary-font-color);
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    color:white;
    width: 100%;
    font-weight: 500;
    font-size:20px;
    padding: 0.5rem 0;
}

@media only screen and (max-width:1024px) and (min-width:690px) {
    .train-coach-details{
        width:90%;
    }

    .train-coach-details h1{
        margin-top: 2rem;
    }

    .train-coach-details h2{
        margin:1rem;
    }

    .choice-btn{
        font-size: 18px;
        margin:0.75rem;
        padding:0.25 0.75rem;
    }

    .berth-number{
        font-size: 16px;
    }
}


@media only screen and (max-width:690px) and (min-width:490px) {
    .train-coach-details{
        width:95%;
    }

    .train-coach-details h1{
        font-size: 1.85rem;
        margin:1.35rem;
        margin-top:2rem;
    }

    .train-coach-details h2{
        font-size: 1.35rem;
        margin:0.75rem;
    }

    .choice-btn{
        font-size: 16px;
        margin:0.55rem;
        padding:0.15 0.55rem;
    }

    .train-coach-details li{
        width:45%;
        margin: 0.5rem auto;
    }

    .berth-number{
        font-size: 16px;
    }
}

@media only screen and (max-width:490px) and (min-width:100px) {
    .train-coach-details{
        width:98%;
    }

    .train-coach-details h1{
        font-size: 1.65rem;
        margin:1rem;
        margin-top:2rem;
    }

    .train-coach-details h2{
        font-size: 1rem;
        margin:0.5rem;
    }

    .choice-btn{
        font-size: 14px;
        margin:0.35rem;
        padding:0.45rem;
    }

    .train-coach-details li{
        width:85%;
        margin: 0.5rem auto;
    }
    
    .berth-number{
        font-size: 14px;
    }
}

.border{
    border: 2px solid currentColor;
    width: fit-content;
    margin: auto;
    padding: 1rem;
    font-weight: 400;
    border-radius: 10px;
}

.seats-count{
    font-weight:300;
    font-size:16px;
}

.review-background{
    position: fixed;
    z-index:10;
    inset: 0;
    background: rgba(156, 144, 144, 0.373);
}

.review-modal{
    box-shadow: 2px 2px 10px 5px gray;
    border: 2px solid currentColor;
    border-radius:15px;
    width:90%;
    max-width:400px;
    margin:auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
    background-color: white;
    & .title{
        border-bottom: 1px solid currentColor;
        padding: 0.5rem;
        font-size: 22px;
        font-weight: 600;
    }

    & .text-message{
        margin: 0.5rem 0;
        padding: 0.5rem;
        word-spacing: 3px;
    }

    & .btn-container{
        margin: 0.5rem 0 1rem;
    }

    & button{
        margin: 0 0.5rem;
        padding: 0.5rem 0.75rem;
    }

    & button:last-of-type{
        background-color: transparent;
        color: currentColor;
        border: 1px solid currentColor
    }

    & a{
        padding: 0.5rem 0.75rem;
        width:100%;
        text-decoration: none;
        color: white;
    }
}

.toggle-container{
    display: flex;
    justify-content: center;
    font-size: 20px;
    gap: 0.5rem;
}