h1{
    font-weight: 500;
    margin: 2rem auto;
    text-align: center;
}
main{
    width:40%;
    margin: auto;
    text-align: center;
}

.input-section{
    width:60%;
    margin:auto;
    position: relative;
}
/* 
.input-section:nth-of-type(3){
    margin-bottom:2rem
} */

.interchange-section{
    width: 60%;
    height:4rem;
    margin:auto;
    position: relative;
}

.btn-interchange{
    width:50px;
    height:50px;
    font-size: 24px;
    position: absolute;
    transform: translateX(50px);
    top:13%;
    right:1% ;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-interchange:hover{
    transform: translateX(50px) scale(1.01);
}

.arrow-up, .arrow-down{
    height:24px;
}

.from-choice, .to-choice, .train-choice{
    background: white;
    padding:0;
    z-index:1;
    list-style: none;
    height: fit-content;
    max-height:190px;
    overflow-y: scroll;
    width:100%;
    margin:auto;
    position: absolute;
    padding-top: 0.2rem;
    top:92%;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.from-choice::-webkit-scrollbar-button, .to-choice::-webkit-scrollbar-button, .train-choice::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar {
    width:6px;
}

::-webkit-scrollbar-thumb{
    width:6px;
    background: var(--primary-font-color);
    border-radius: 5px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);;
}

.from-choice li, .to-choice li, .train-choice li {
    cursor: pointer;
    width: 100%;
    margin:3px 0;
    color: var(--primary-font-color);
    padding:0.5rem  ;
    box-sizing: border-box;
}

.from-choice li:hover, .to-choice li:hover, .train-choice li:hover{
    background: var(--primary-font-color);
    color:white;
    border-radius: 10px;
} 

.action-btn{
    box-sizing: border-box;
    padding: 1rem 2rem;
    font-size:20px;
    margin: 2rem auto;
}

.or-section{
    display: flex;
    margin: auto;
    margin-bottom: 2rem;
    font-size: 18px;
    font-weight: 600;
    width: 60%;
    align-items: center;
    justify-content: space-between;
}

.or-section span:nth-of-type(odd){
    height: 0px;
    display: inline-block;
    width: 40%;
    border: 1px solid var(--primary-font-color);
    
}

.date-btn{
    padding: 0.5rem;
    font-size: 1rem;
    margin: 0 0.25em;
}

.date-btn-container{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width:1024px) and (min-width:700px) {
    main{
        width:60%;
    }
}

@media only screen and (max-width:700px) and (min-width:500px) {
    main{
        width:80%;
    }  
} 

@media only screen and (max-width:500px) and (min-width:300px) {
    main{
        width:95%;
    }

    .input-section{
        width: 80%;
    }

    main>img{
        width: 90%
    }
}

@media only screen and (max-width:300px) and (min-width:100px) {
    main{
        width:100%;
    }

    .input-section{
        width: 80%;
    }

    main>img{
        width: 90%
    }
}